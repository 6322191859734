<script setup lang="ts" generic="T extends { [K in string]: string | number } & { disabled?: boolean; icon?: IconName }">
import { useElementBounding } from '@vueuse/core';
import { useField } from 'vee-validate';
import type { IconName } from '~/components/ui/Icon.vue';

// Composables
const { getHeight, getTextSize, getInputColor, getSelectOpenAnimation } = useInput();

// Props
const modelValue = defineModel<T[typeof props.itemKey] | undefined>();
const props = withDefaults(
  defineProps<{
    name: string;
    label?: string;
    required?: boolean;
    size?: InputSize;
    leadingIcon?: IconName;
    leadingText?: string;
    trailingIcon?: IconName;
    items: T[];
    disabled?: boolean;
    placeholder?: string;
    searchable?: boolean;
    searchPlaceholder?: string;
    hideDetails?: boolean;
    searchKey?: (keyof T)[];
    fitToSelect?: boolean;
    content?: {
      direction?: 'left' | 'bottom' | 'right' | 'top';
      align?: 'start' | 'center' | 'end';
      sideOffset?: number;
    };
    disabledMessage?: string;
    itemKey?: keyof T;
    itemLabel?: keyof T;
    itemSecondaryLabel?: keyof T;
  }>(),
  {
    label: undefined,
    leadingIcon: undefined,
    leadingText: undefined,
    trailingIcon: 'ChevronDown',
    size: 'md',
    itemKey: 'id',
    itemLabel: 'label',
    disabled: false,
    placeholder: undefined,
    modelValue: undefined,
    searchable: false,
    fitToSelect: false,
    searchPlaceholder: undefined,
    hideDetails: true,
    itemSecondaryLabel: undefined,
    disabledMessage: undefined,
    searchKey: () => ['label'],
    content: () => ({
      direction: 'bottom',
      align: 'start',
      sideOffset: 4,
    }),
  },
);

// Data
const open = ref(false);
const search = ref('');
const input = ref(null);
const { width } = useElementBounding(input);
const { errorMessage, value, handleChange, setValue } = useField<T[typeof props.itemKey]>(props.name, undefined, {
  initialValue: modelValue.value,
  valueProp: modelValue.value,
});

// Computed
const filteredItems = computed(() => {
  if (props.searchable) {
    return props.items.filter((item) => {
      return props.searchKey.some((key) => String(item[key]).toLowerCase().includes(search.value.toLowerCase()));
    });
  }
  return props.items;
});

// Methods
const selectItem = (item: T) => {
  if (item?.disabled) return;
  handleChange(item[props.itemKey]);
  modelValue.value = item[props.itemKey];
  open.value = false;
};

// Lifecycle
watch(
  modelValue,
  (newValue) => {
    if (newValue) {
      setValue(newValue);
    }
  },
  { immediate: true },
);
</script>
<template>
  <div class="text-gray-600 w-full relative" :class="[hideDetails ? '' : 'mb-[16px]']">
    <popover-root v-model:open="open" class="w-full">
      <label v-if="label" :class="errorMessage ? 'text-red-300' : 'text-gray-600'" class="font-medium text-xs mb-[6px] block text-left">
        {{ label }}<span v-if="required" class="text-red-500">*</span>
      </label>
      <popover-trigger class="w-full">
        <slot name="trigger" :open="open">
          <div
            ref="input"
            class="px-2.5 border rounded-md flex justify-between items-center w-full bg-white"
            :class="[getHeight(size), getTextSize(size), getInputColor({ errorMessage, disabled, open })]"
          >
            <div class="flex items-center gap-2">
              <ui-icon v-if="leadingIcon" :name="leadingIcon" class="h-4 w-4 stroke-gray-600" />
              <span class="block flex-1 text-gray-600 truncate select-none">
                <span v-if="leadingText" class="text-gray-600">{{ leadingText }} : </span>
                {{ items.find((el) => el?.[props.itemKey] === value)?.[props.itemLabel] ?? placeholder ?? $t('global.select_an_item') }}
              </span>
            </div>
            <div class="flex items-center pl-2">
              <ui-icon v-if="trailingIcon" :name="trailingIcon" class="text-gray-600 w-4" :stroke-width="2" />
            </div>
          </div>
        </slot>
      </popover-trigger>
      <popover-portal :disabled="disabled">
        <popover-content
          position-strategy="fixed"
          :side="content.direction"
          :align="content.align"
          :side-offset="content.sideOffset"
          :style="{ width: fitToSelect ? `${width}px` : 'auto' }"
          :class="[getSelectOpenAnimation()]"
          class="bg-white z-[8000] min-h-[100%] shadow border rounded-md border-gray-200"
        >
          <div v-if="searchable" class="border-b border-gray-200">
            <input
              v-model="search"
              type="text"
              :placeholder="searchPlaceholder ?? $t('global.search')"
              class="w-full px-2.5 py-1.5 text-sm focus:outline-none rounded-md"
            />
          </div>

          <div v-if="filteredItems.length === 0" class="p-1 pt-3 w-full flex justify-center items-center">
            <p class="text-sm text-gray-600">{{ $t('global.no_results') }}</p>
          </div>

          <ul class="p-1 max-h-[150px] overflow-y-auto">
            <li v-for="item in filteredItems" :key="item[props.itemKey]" class="space-y-2" @click="selectItem(item)">
              <slot name="item" :item="item">
                <div
                  class="select-none flex items-center justify-between px-2 py-1.5 rounded-md"
                  :class="[getTextSize(size), item?.disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50 cursor-pointer']"
                >
                  <div class="flex items-center gap-2">
                    <ui-icon v-if="item?.icon" :name="item?.icon" class="w-4 h-4 text-gray-700" />
                    <p class="whitespace-nowrap text-gray-600">
                      {{ String(item?.[props.itemLabel]) }}
                    </p>
                  </div>
                  <ui-icon v-if="item?.[props.itemKey] === value" name="Check" class="w-4 h-4 ml-4 text-gray-600" />
                  <div v-if="props.itemSecondaryLabel && item?.[props.itemSecondaryLabel]" class="flex items-center gap-1">
                    <p class="text-gray-500 text-[10px]">{{ item[props.itemSecondaryLabel!] }}</p>
                    <ui-icon name="ArrowUpRight" class="w-3 h-3 text-gray-500" />
                  </div>
                  <nrjx-tooltip v-if="item.disabled" :message="disabledMessage" side="left" :side-offset="34">
                    <ui-icon name="Info" class="w-4 h-4 ml-4 text-gray-600" />
                  </nrjx-tooltip>
                </div>
              </slot>
            </li>
          </ul>
        </popover-content>
      </popover-portal>
    </popover-root>
    <span v-if="errorMessage" class="absolute right-0 -bottom-[18px] text-red-500 text-xs">
      {{ errorMessage }}
    </span>
  </div>
</template>
