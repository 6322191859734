<script setup lang="ts">
withDefaults(
  defineProps<{
    message: string;
    side?: 'top' | 'bottom' | 'left' | 'right';
    sideOffset?: number;
  }>(),
  {
    side: 'top',
    sideOffset: 5,
  },
);

const open = ref(false);
const showTooltip = () => {
  open.value = true;
};
</script>
<template>
  <tooltip-root v-if="message" :open="open">
    <tooltip-trigger>
      <div @mouseenter="showTooltip" @mouseleave="open = false">
        <slot />
      </div>
    </tooltip-trigger>
    <tooltip-portal>
      <tooltip-content
        :side="side"
        class="z-[9999] data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-white select-none rounded-md bg-gray-800/80 px-[15px] py-[10px] text-xs leading-none shadow-sm will-change-[transform,opacity]"
        :side-offset="sideOffset"
      >
        {{ message }}
      </tooltip-content>
    </tooltip-portal>
  </tooltip-root>
  <slot v-else />
</template>
